
  export  const cos =[
    {  image: './assests/cosmetics/p1.png' },
    {  image: './assests/cosmetics/CC Cream.png' },
    {  image: './assests/cosmetics/Eye shadow.png' },
    {  image: './assests/cosmetics/Eyeliner.png' },
    {  image: './assests/cosmetics/Lip gloss.png' },
    {  image: './assests/cosmetics/Scrab.png' },
    {  image: './assests/cosmetics/Brownzer.png' },
    {  image: './assests/cosmetics/Concealer.png' },
  ];
export  const paper=[

    {  image: './assests/Paper/Carbon paper.png' },
    {  image: './assests/Paper/Carbonless.png' },
    {  image: './assests/Paper/Chalk Paper.png' },
    {  image: './assests/Paper/Construction paper.png' },
    {  image: './assests/Paper/Cotton paper.png' },
    {  image: './assests/Paper/Cover Stock.png' },
    {  image: './assests/Paper/Graph paper.png' },
    {  image: './assests/Paper/Kraft paper.png' },
  ];
export  const stationary= [
    {  image: './assests/Stationery/Calculator.png' },
    {  image: './assests/Stationery/Clip.png' },
    {  image: './assests/Stationery/colored folder.png' },
    {  image: './assests/Stationery/Cuter.png' },
    {  image: './assests/Stationery/Greeting cards.png' },
    {  image: './assests/Stationery/Highlighters.png' },
    {  image: './assests/Stationery/Invitations.png' },
    {  image: './assests/Stationery/Notecards.png' },
  ];
 export const disposable = [
    { image: './assests/Disposable/19.png' },
    { image: './assests/Disposable/20.png' },
    { image: './assests/Disposable/Tea.png' },
    { image: './assests/Disposable/14.png' },
    { image: './assests/Disposable/16.png' },
    { image: './assests/Disposable/Ice.png' },
    { image: './assests/Disposable/15.png' },
    { image: './assests/Disposable/3.png' }, 
  ];
export  const garment =[
  {  image: './assests/Garments/5.png' },
  {  image: './assests/Garments/15.png' },
  {  image: './assests/Garments/rompers.png' },
  {  image: './assests/Garments/10.png' },
  {  image: './assests/Garments/Men/1.png' },
  {  image: './assests/Garments/Men/9.png' },
  {  image: './assests/Garments/Men/5.png' },
  {  image: './assests/Garments/Men/4.png' },
  {  image: './assests/Garments/Women/c4.png' },
  {  image: './assests/Garments/Women/c2.png' },
  {  image: './assests/Garments/Women/c20.png' },
  {  image: './assests/Garments/Women/c25.png' },
];
export const product = [
  {
    id: "1",
    title : "Cosmetics",
    para:"Beauty products enhance personal care and grooming by nourishing the skin, hair, and body. They include cosmetics, skincare, fragrances, and hair care items tailored to various needs. With a focus on health and appearance, these products boost confidence and promote self-expression. Quality beauty products often combine natural ingredients with scientific advancements for effective results.",
    images: [
       '../assests/cosmetics/CC Cream.png' ,
       '../assests/cosmetics/Eye shadow.png' ,
       '../assests/cosmetics/Eyeliner.png' ,
       '../assests/cosmetics/Lip balm.png' ,
       '../assests/cosmetics/Lipstick.png' ,
       '../assests/cosmetics/Mascara.png' ,
       '../assests/cosmetics/Moisturizer.png',
        '../assests/cosmetics/Primer.png' ,
        '../assests/cosmetics/Setting Spray.png' ,
        '../assests/cosmetics/Foundation1.png' ,
        '../assests/cosmetics/Blush.png' ,
        '../assests/cosmetics/Brownzer.png' ,
        '../assests/cosmetics/Concealer.png' ,
        '../assests/cosmetics/Colorcorrector.png',
        '../assests/cosmetics/Compact.png' ,
        '../assests/cosmetics/Highlighter 2.png',
        '../assests/cosmetics/Face powder.png' ,
        '../assests/cosmetics/Foundation.png' ,
        '../assests/cosmetics/Gel eyelinear.png',
        '../assests/cosmetics/Highlighter.png' ,
        '../assests/cosmetics/Liner1.png' ,
        '../assests/cosmetics/Lip gloss.png',
        '../assests/cosmetics/Scrab.png' ,
       '../assests/cosmetics/p1.png',
    ],

  },
  {
    id: "2",
    title : "Paper",
    para: "Paper products play a vital role in daily life, ranging from notebooks, tissues, and packaging materials to office supplies. They are made from renewable resources, with many options now focusing on eco-friendly and recycled materials. These products support communication, hygiene, and storage needs across industries. Their versatility makes them essential in homes, schools, and workplaces worldwide.",
    images: [
       '../assests/Paper/Carbon paper.png' ,
     '../assests/Paper/Carbonless.png' ,
     '../assests/Paper/Chalk Paper.png' ,
     '../assests/Paper/Construction paper.png' ,
     '../assests/Paper/Cotton paper.png' ,
     '../assests/Paper/Cover Stock.png' ,
     '../assests/Paper/Graph paper.png' ,
     '../assests/Paper/Kraft paper.png' ,
     '../assests/Paper/Printing paper.png' ,
     '../assests/Paper/Rice paper.png' ,
     '../assests/Paper/Stickers.png' ,
     '../assests/Paper/Tag Paper.png' ,
     '../assests/Paper/Tissue paper.png' ,
     '../assests/Paper/Tracing paper.png' ,
     '../assests/Paper/Waxed paper.png' ,
     '../assests/Paper/17.png',
     
   ],
    
  },
  {
    id: "3",
    title : "Stationary",
    para: "Stationery products include essential items like pens, notebooks, rulers, and adhesives used for writing, organizing, and crafting. They are widely utilized in schools, offices, and homes to support productivity and creativity. With a variety of designs and functions, stationery caters to both practical needs and personal expression. High-quality and innovative stationery can enhance efficiency and add charm to everyday tasks.",
    images: [
       '../assests/Stationery/22.png' ,
     '../assests/Stationery/board pens.png',
     '../assests/Stationery/Business Stationery.png',
     '../assests/Stationery/Calculator.png' ,
     '../assests/Stationery/Clip.png' ,
     '../assests/Stationery/colored folder.png',
     '../assests/Stationery/Cuter.png' ,
     '../assests/Stationery/Envelopes.png',
     '../assests/Stationery/Erasers.png' ,
     '../assests/Stationery/Greeting cards.png',
     '../assests/Stationery/Highlighters.png' ,
     '../assests/Stationery/Invitations.png' ,
     '../assests/Stationery/Notecards.png' ,
     '../assests/Stationery/Notepads.png' ,
     '../assests/Stationery/Pencils.png' ,
     '../assests/Stationery/Pens.png' ,
     '../assests/Stationery/pin.png' ,
     '../assests/Stationery/Personalized stationery.png' ,
     '../assests/Stationery/Postcards.png' ,
     '../assests/Stationery/Rubber.png' ,
     '../assests/Stationery/Stapler.png' ,
     '../assests/Stationery/sticky notes.png',
     '../assests/Stationery/Taps.png' ,
     '../assests/Stationery/Writing Paper.png',
   ],
    
  },
  {
    id: "4",
    title : "Disposable",
    para:"Disposable products are designed for single-use to provide convenience and maintain hygiene across various settings. They include items like plates, cups, gloves, masks, and packaging materials used in homes, healthcare, and hospitality industries. These products help reduce the spread of contamination but also contribute to waste, prompting a shift towards biodegradable and eco-friendly alternatives. Disposables offer practicality, especially for events and medical purposes, where quick disposal is essential.",
    images: [
       '../assests/Disposable/19.png',
       '../assests/Disposable/20.png',
       '../assests/Disposable/21.png',
       '../assests/Disposable/22.png',
       '../assests/Disposable/23.png',
       '../assests/Disposable/24.png',
       '../assests/Disposable/3.png' ,
       '../assests/Disposable/7.png' ,
       '../assests/Disposable/8.png',
       '../assests/Disposable/9.png',
       '../assests/Disposable/10.png',
       '../assests/Disposable/11.png',
       '../assests/Disposable/12.png',
       '../assests/Disposable/13.png',
       '../assests/Disposable/14.png',
       '../assests/Disposable/15.png',
       '../assests/Disposable/16.png',
       '../assests/Disposable/17.png',
       '../assests/Disposable/18.png',
       '../assests/Disposable/Glass (2).png',
       '../assests/Disposable/Glass.png' ,
       '../assests/Disposable/Ice.png' ,
       '../assests/Disposable/Plates.png',
       '../assests/Disposable/Tea.png',
   ],
    
  },
  {
    id: "5",
    title : "Garments",
    para:"Garments are clothing items that serve both functional and aesthetic purposes, providing comfort, protection, and style. They come in a variety of materials, designs, and categories, including casual wear, formal attire, and sportswear. The garment industry plays a crucial role in fashion trends, personal expression, and cultural identity. With increasing awareness, there is a growing demand for sustainable and eco-friendly clothing options.",
    images: [
      '../assests/Garments/Men/1.png',
      '../assests/Garments/Men/2.png',
      '../assests/Garments/Women/c13.png',
      '../assests/Garments/16.png' ,
      '../assests/Garments/Men/5.png',
      '../assests/Garments/Men/7.png',
      '../assests/Garments/Men/3.png',
      '../assests/Garments/Women/c5.png' ,
      '../assests/Garments/Men/8.png',
      '../assests/Garments/Men/9.png',
      '../assests/Garments/Men/6.png',
      '../assests/Garments/Men/10.png',
      '../assests/Garments/Women/c7.png' ,
      '../assests/Garments/Women/c8.png' ,
      '../assests/Garments/12.png' ,
      '../assests/Garments/13.png' ,
      '../assests/Garments/14.png' ,
      '../assests/Garments/Women/c4.png' ,
      '../assests/Garments/15.png' ,
      '../assests/Garments/11.png' ,
      '../assests/Garments/17.png' ,
      '../assests/Garments/Nightwear,.png' ,
      '../assests/Garments/ready-to-wear.png' ,
      '../assests/Garments/Women/c9.png' ,
      '../assests/Garments/Women/c10.png',
      '../assests/Garments/Women/c11.png',
      '../assests/Garments/Men/4.png',
      '../assests/Garments/Women/c12.png',
      '../assests/Garments/Women/c14.png',
      '../assests/Garments/Women/c15.png',
    
   ],
    
  },
  {
    id: "6",
    title : "Men",
    para:"Men's garments include a wide range of clothing designed for different occasions, such as t-shirts, shirts, trousers, jeans, suits, blazers, and jackets. Casual wear like polo shirts and denim jeans offer comfort for everyday activities, while **formal suits and dress shirts** are essential for professional or formal events. Activewear such as tracksuits and hoodies caters to fitness and leisure. With evolving fashion, men’s garments also focus on style, fit, and sustainable fabrics, offering versatility for all seasons and preferences.",
    images: [
       '../assests/Garments/Men/1.png',
       '../assests/Garments/Men/2.png',
       '../assests/Garments/Men/4.png',
       '../assests/Garments/Men/5.png',
       '../assests/Garments/Men/7.png',
       '../assests/Garments/Men/3.png',
       '../assests/Garments/Men/8.png',
       '../assests/Garments/Men/9.png',
       '../assests/Garments/Men/6.png',
       '../assests/Garments/Men/10.png',
   ],
    
  },
  {
    id: "7",
    title : "Women",
    para:"Women's garments offer a diverse range of clothing for various occasions, including dresses, blouses, skirts, trousers, and jeans. Sarees, salwar kameez, and lehengas are popular for traditional wear, while gowns and cocktail dresses are favored for formal events. Casual outfits like t-shirts, leggings, and sweaters provide everyday comfort, while activewear such as yoga pants and sports dress cater to fitness needs. With a focus on fashion and functionality, women's garments embrace trends, personal expression, and sustainable choices.",
    images: [
       '../assests/Garments/Women/c1.png' ,
       '../assests/Garments/Women/c2.png' ,
       '../assests/Garments/Women/c3.png' ,
       '../assests/Garments/Women/c4.png' ,
       '../assests/Garments/Women/c5.png' ,
       '../assests/Garments/Women/c7.png' ,
       '../assests/Garments/Women/c8.png' ,
       '../assests/Garments/Women/c9.png' ,
       '../assests/Garments/Women/c10.png',
       '../assests/Garments/Women/c11.png',
       '../assests/Garments/Women/c12.png',
       '../assests/Garments/Women/c13.png',
       '../assests/Garments/Women/c14.png',
       '../assests/Garments/Women/c15.png',
       '../assests/Garments/Women/c16.png',
       '../assests/Garments/Women/c17.png',
       '../assests/Garments/Women/c18.png',
       '../assests/Garments/Women/c19.png',
       '../assests/Garments/Women/c20.png',
       '../assests/Garments/Women/trouser.png',
       '../assests/Garments/Women/c21.png',
       '../assests/Garments/Women/c22.png',
       '../assests/Garments/Women/c23.png',
       '../assests/Garments/Women/c24.png',
       '../assests/Garments/Women/c25.png',
       '../assests/Garments/Women/c6.png',
   ],
    
  },
  {
    id: "8",
    title : "Kids",
    para:"Kid's garments are designed for comfort, durability, and style, catering to different activities and seasons. Common items include t-shirts, shorts, jeans, hoodies, and pajamas for casual wear. For formal occasions, kids can wear dresses, suits, and frocks, while school uniforms are essential for daily use. Winter wear like jackets, sweaters, and mittens ensures warmth, and rompers and onesies are popular choices for infants. Kids' clothing emphasizes playful designs, bright colors, and soft fabrics to suit their active lifestyles.",
    images: [
       '../assests/Garments/5.png' ,
       '../assests/Garments/6.png' ,
       '../assests/Garments/7.png' ,
       '../assests/Garments/8.png' ,
       '../assests/Garments/9.png' ,
       '../assests/Garments/10.png' ,
       '../assests/Garments/16.png' ,
       '../assests/Garments/12.png' ,
       '../assests/Garments/13.png' ,
       '../assests/Garments/14.png' ,
       '../assests/Garments/15.png' ,
       '../assests/Garments/11.png' ,
       '../assests/Garments/17.png' ,
       '../assests/Garments/Nightwear,.png' ,
       '../assests/Garments/ready-to-wear.png' ,
       '../assests/Garments/rompers.png' ,
       '../assests/Garments/Shorts.png' ,
       '../assests/Garments/18.png' ,
   ],
    
  },
]