import React from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom';
import WhatsappButton from '../Components/WhatsappButton';
import { cos, disposable, garment, paper, stationary } from '../Data/productsApi';

const Home = () => {
 
  return (
    <Layout>
      <WhatsappButton/>
     
{/* //Slider start */}

<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="./assests/banner/2.png" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="./assests/banner/1.png" className="d-block w-100"  alt="..." />
    </div>
    <div className="carousel-item">
      <img src="./assests/banner/3.png" className="d-block w-100" alt="..." />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

{/* end carousel */}

          {/* cosmetics product  map*/}
          <div className='container'>
            <div className="row">
            <h2 className='mt-4'>Best Sellers in Beauty & Personal Care </h2>
              {cos.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
                  <div className="custom-card card" id="card" style={{ width: '100%' }}>
          
                    <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* end map */}

           {/* start carousel for beauty products  */}
  
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="./assests/banner/slider1.png"  className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="./assests/banner/slider2.png"  className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="./assests/banner/slider3.png"  className="d-block w-100" alt="..." />
    </div>
  </div>
</div>
 {/* end carousel  */}

  {/* paper product  map*/}
          <div className='container'>
            <div className="row">
            <h2 className='mt-3'>Best Sellers in Paper Products </h2>
              {paper.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
                  <div className="custom-card card" id="card"  style={{ width: '100%' }}>
          
                    <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* end map */}

          {/* start carousel for paper  */}

    <div id="carouselExampleSlidesOnly " className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active my-5">
          <img src="./assests/banner/4.png"  className="d-block w-100" alt="..." />
        </div>
      </div>
    </div>
{/* end carousel */}

{/* stationary images map */}

<div className='container'>
        <div className="row">
        <h2>Best Sellers in Stationary Products </h2>
          {stationary.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div className="custom-card card"  id="card"  style={{ width: '100%' }}>
                <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map  */}

 {/* disposable product  map*/}
 <div className='container'>
            <div className="row">
            <h2>Best Sellers in Disposable Products </h2>
              {disposable.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
                  <div className="custom-card card" id="card"  style={{ width: '100%' }}>
          
                    <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* end map */}

{/* start carousel for diposable  */}

    <div id="carouselExampleSlidesOnly " className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active my-5">
          <img src="./assests/banner/5.png"  className="d-block w-100" alt="..." />
        </div>
      </div>
    </div>
{/* end carousel */}

{/* garment product  map*/}
<div className='container'>
            <div className="row">
            <h2>Best Sellers in  Garments </h2>
              {garment.map((ele, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
                  <div className="custom-card card" id="card"  style={{ width: '100%' }}>
          
                    <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* end map */}

      {/* service section start */}
     <div className='container'>
     <div class="py-5 service-37 wrap-service37-box">
  <div class="row ">
    <div class="col-lg-5"> <img src="./assests/banner/6.png" alt="wrapkit" class="img-fluid" /> </div>
    <div class="col-lg-7 align-self-center">
      <div class="card max-600 border-0">
        <div class="card-body">
          <h3 class="mb-3 text-uppercase" id='hed'>AWESOME EXTRA ORDINARY FLEXIBILITY</h3>
          <p class="mt-3">You can relay on our amazing products list and also our customer services will be greatexperience for you without doubt and in no-time and with great quality for design.</p>
          <div class="card card-shadow border-0 mb-3">
            <div class="card-body">
              <div class="d-block d-md-flex p-2">
                <div class="mr-3 align-self-center d-none d-md-block">
									<span class="text-success-gradiant display-5"><i class="icon-cloud-download"></i></span>
								</div>
                <div class="ml-auto align-self-center"><Link
                 class="btn btn-info-gradiant rounded-pill btn-md" id='btn' to="/cosmetics"><span>Shop now</span></Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
     </div>

    </Layout>
  )
}

export default Home
