import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { product } from '../../Data/productsApi';
import Layout from '../Layout';
import WhatsappButton from '../WhatsappButton'
import './product.css'
const Product = () => {
  const {proID} = useParams()
  let filterProduct = product.find(x => x.id === proID)
  return (
    <Layout>
        <WhatsappButton/>
      <div className='container-fluid' id='container'>
        <div className='row'>
       
        <div className='col-md-9 col-sm-12 right '>
          <h1 className='p-3 ms-4 mb-3 fw-bold' style={{color:" rgb(144, 19, 117)"}}>{filterProduct.title} </h1>
          <p className='mx-5  ' style={{textAlign:"justify"}}>{filterProduct.para} </p>
        {filterProduct.images.map((img, index) => (
          <img className='my-5 add'
            key={index}
            src={img}
            alt={`Cosmetic ${index + 1}`}
            style={{ width: "310px", height: "300px", marginLeft: "12px" }}
            />
        ))}
          </div>
          <div className='col-md-3 col-sm-12 left bg-light'>
         <h1 className='mt-3 p-2' >Categories</h1>
         <div className='mt-4'>
          {
            product.map((ele, index) =>(
              <div key={index}>
                <ul id='ul' style={{listStyleType:"none"}}>
                  <li  id='ul' >
                  <Link className='filter-btn' to={`/${ele.title}/${ele.id}` }  > {ele.title}</Link> 
                  </li>
                </ul>
                </div>
            ))

            
          }
         </div>
        </div>
          </div>
        </div>
    </Layout>
  )
}

export default Product
