import React from 'react'
import Layout from '../Components/Layout'

import { MDBContainer, MDBTable ,  MDBTableBody} from 'mdbreact';
import jobData from '../Data/jobData'
import WhatsappButton from '../Components/WhatsappButton';
import { Link } from 'react-router-dom';
const JobPage = () => {

  return(
    <Layout>
<div className='color'>

<WhatsappButton/>
<div className='container'>
  <nav className="navbar navbar-light bg-body-tertiary">
    <div className="container">
      <p className=" fs-4 mt-4 ">View Jobs</p>
    </div>
  </nav>


<MDBContainer>
  <MDBTable responsive bordered striped>
    <MDBTableBody>
      {jobData.map((i) => (
        <tr key={i.id}>
          <td>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{i.title}</h5>
                <p className="card-text">{i.company}</p>
                <p className="card-text"><i className="fa-solid fa-money-bill-1-wave me-1"></i>{i.salary}</p>
                <p className="card-text"><i className="fa-solid fa-location-crosshairs"></i>{i.location}</p>
                <p className="card-text">Skills: {i.skills}</p>
                <p className="card-text">{`${i.description.slice(0, 200)}...`}</p>
                <Link to={i.Link} className="btn btn-primary read_more_btn">Job Detail</Link>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </MDBTableBody>
  </MDBTable>
</MDBContainer>

    
 


<br/>
</div>
</div>

</Layout>
);
};
 



export default JobPage;
