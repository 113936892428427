import React from 'react'
import { Link } from 'react-router-dom'
import SelectDrop from '../Components/selectDrop/SelectDrop';
import { product} from '../Data/productsApi'


const Navbar = () => {
 
  return (
    <>
    {/* header top start here */}

    <div className="p-3 text-center bg-white border-bottom">
    <div className="container">
      <div className="row">
        {/* Left elements */}
        <div className="col-md-3 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
          <Link to='/' className="ms-md-2">
            <img src="../assests/A3HMIR.png" height={65} alt='logo' />
          </Link>
        </div>
        {/* Left elements */}
        {/* Center elements */}
        <div className="col-md-6 my-auto">
          <form className="d-flex input-group w-auto  mb-md-0">
          <div className='headerSearch d-flex align-items-center border '>
                  
                       <SelectDrop data= {product.slice(0,5).map((ele, index) => (
                                  <div key={index}    >
                                   <Link to={`/${ele.title}/${ele.id}`} className="filter-btn "> {ele.title}
                                  </Link>
                                  </div>
                                ))} 
                    placeholder='All Categories'
                     icon={false} />
                    
                    <div className='search'>
                      
                      {/* <input type='text' placeholder='Search for items'/> */}
                     <i className="fas fa-angle-down searchicon cursor" style={{fontSize: 24}} />

                    {/* <SearchIcon className='searchicon cursor'/> */}
                    </div>
                    </div>
            {/* <span className="input-group-text border-0 d-none d-lg-flex"><i className="fas fa-search" /></span> */}
          </form>
        </div>
        {/* Center elements */}
        {/* Right elements */}
        <div className="col-md-3 d-flex justify-content-center justify-content-md-end align-items-center mt-">
          <div className="d-flex">
          <ul className="navbar-nav flex-row">
            {/* Icons */}
            
            <li className="nav-item">
            <Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-facebook-f p-2 me-2 fs-5" id='icon'></i></Link>
              
            </li>
            <li className="nav-item">
            <Link to="https://www.linkedin.com/company/mirglobalcorporation/" target="_blank"> <i class="fa-brands fa-linkedin-in me-2 fs-5 p-2" id='icon'></i></Link> 
            </li>
            <li className="nav-item">
            <Link to="https://www.instagram.com/a3hmirglobalcorporations/" target="_blank"> <i class="fa-brands fa-instagram-square p-2 me-2 fs-5" id='icon'></i></Link>
            </li>
            <li className="nav-item">
            <Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-x-twitter p-2 me-2 fs-5" id='icon'></i></Link>
            </li>
          </ul>
          </div>
        </div>
        {/* Right elements */}
      </div>
    </div>
  </div>
    {/* header top end here */}
<div className='container-fluid'>
<nav className="navbar top-fixed navbar-expand-lg navbar-light  ">
  <div className="container-fluid">
    <button className="navbar-toggler bg-light"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    
    
  <ul className="navbar-nav m-auto   mb-2 mb-lg-0" >
    <li className="nav-item    me-1">
      <Link className="nav-link active fs-5 " to='/' aria-current="page" id='col' >Home</Link>
    </li>
    {/* Vertical Line */}
    <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item   ">
      <Link className="nav-link  fs-5" to='/about' id='col'>About</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item dropdown  ">
      <Link className="nav-link dropdown-toggle   fs-5"  to='/products' id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
        Products
      </Link>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
      <li><Link className="proitem" to='/garments'>{product.slice(0,5).map((ele, index) => (
                                  <div key={index}    >
                                   <Link to={`/${ele.title}/${ele.id}`} className="filter-btn "> {ele.title}
                                  </Link>
                                  </div>
                                ))} </Link></li>
      </ul>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/contact'  id='col' >Contact</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/job' id='col'  >Career</Link>
    </li>
    
  </ul> </div>
  <div className=' px-3 text-light book'>
    BOOK YOUR ORDER NOW<br/>
    +971-562388456
  </div>
  
     
    </div>
</nav>
</div>
    </>
  )
}

export default Navbar
